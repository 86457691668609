var app = require('../app')

app.directive('sidebarEvent', function () {
  return {
    replace: true,
    restrict: 'EA',
    scope: {
      event: '=',
      sites: '='
    },
    templateUrl: 'assets/partials/sidebar-event.html',
    controller: 'SidebarEventController'
  }
})
