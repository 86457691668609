var app = require('../app')

var $ = require('jquery')

app.controller('CalendarController', function ($rootScope, $scope, $timeout, $localStorage, Session, uiCalendarConfig, ShowEventModal, User, Site, Event) {
  $scope.$storage = $localStorage.$default({
    selected: []
  })

  var getSelectedSites = function () {
    var sel = []
    $scope.sites.forEach(function (site, index) {
      if ($scope.$storage.selected[index]) {
        sel.push(site.id)
      }
    })
    return sel.join(',')
  }

  $scope.sites = []

  User.getSession().$promise.then(function (user) {
    Session.user = user
  })

  $scope.updateCalendar = function (start, end, timezone, callback) {
    Event.queryInterval({
      sites: getSelectedSites(),
      from: new Date(start),
      to: new Date(end),
      noHoliday: $scope.calendarView === 'month'
    }).$promise.then(function (events) {
      events.forEach(function (event) {
        var accessLevel = 0
        $scope.sites.forEach(function (site) {
          if (site.id === event.site) {
            event.textColor = site.color
            accessLevel = site.permission
          }
        })
        if (!event.title || !event.title.length) {
          accessLevel = 0
          delete event.textColor
          event.color = 'gray'
        }
        if (event.holiday) {
          accessLevel = 0
        }
        switch (accessLevel) {
          case 0:
            event.editable = false
            break
          case 1:
            event.editable = false
            break
          case 2:
            event.editable = true
            event.deletable = true
            break
        }
      })
      callback(events)
    }).catch(function (err) {
      console.error(err)
      callback([])
    })
  }

  $scope.refreshSites = function () {
    $scope.sites = Site.query()
  }

  $scope.$watch('user', function () {
    $scope.refreshSites()
  })

  $scope.newEvent = function () {
    ShowEventModal(null)
  }

  $scope.eventSources = [$scope.updateCalendar]

  $scope.calendarStyle = 'calendar'
  $scope.calendarView = 'month'

  $scope.changeView = function (view, style) {
    $scope.calendarView = view
    $scope.calendarStyle = style
    var cal = uiCalendarConfig.calendars['calendar']
    if (cal && style === 'calendar') {
      cal.fullCalendar('changeView', view)
      cal.fullCalendar('refetchEvents')
      cal.fullCalendar('render')
    }
  }

  $scope.refreshCalendar = function () {
    var cal = uiCalendarConfig.calendars['calendar']
    if (cal) {
      cal.fullCalendar('refetchEvents')
    }
  }

  $scope.$watch('selectedSites', function () {
    $scope.refreshCalendar()
  })

  var calculateCalendarHeight = function () {
    var $window = $(window)
    var $navbar = $('nav.navbar-fixed-top')

    return $window.height() - $navbar.height() -
           parseInt($navbar.css('margin-bottom'))
  }

  $scope.toggleWeekends = function () {
    $scope.calendarConfig.weekends = !$scope.calendarConfig.weekends
  }

  var getSiteFromId = function (id) {
    var foundSite = null
    $scope.sites.forEach(function (site) {
      if (site.id === id) {
        foundSite = site
      }
    })
    return foundSite
  }

  var checkCollision = function (event) {
    if (Session.user.admin) {
      return Promise.resolve(false)
    }
    if (getSiteFromId(event.site).disableParallelEvents) {
      return Event.queryInterval({
        sites: [event.site],
        from: new Date(event.start),
        to: new Date(event.end)
      }).$promise.then(function (events) {
        for (var i = 0; i < events.length; i++) {
          var event = events[i]
          if (event.holiday) {
            return Promise.reject(new Error('cannot place over holiday'))
          }
        }
        return Promise.resolve(false)
      })
    } else {
      return Promise.resolve(false)
    }
  }

  $scope.selectEvent = function (event) {
    if (event.editable && $scope.selectedEvent &&
        event.id === $scope.selectedEvent.id) {
      $scope.selectedEvent = event
      ShowEventModal(event, $scope.sites).result.then(function (savedEvent) {
        $scope.refreshCalendar()
        $scope.selectedEvent = savedEvent
      })
    } else if (event.title && event.title.length && !event.holiday) {
      $scope.selectedEvent = event
      $rootScope.showSidebar = true
    }
  }

  $scope.calendarConfig = {
    lang: 'hu',
    header: {
      left: 'prev title next',
      center: '',
      right: ''
    },
    height: calculateCalendarHeight(),
    editable: true,
    windowResize: function (view) {
      $scope.calendarConfig.height = calculateCalendarHeight()
    },
    timezone: 'local',
    firstDay: 1,
    weekends: true,
    slotDuration: '00:15:00',
    dayClick: function (date, jsEvent, view) {
      var nevent = {
        start: date.clone().local(),
        end: date.clone().local()
      }
      if (!date.hasTime()) {
        nevent.start.hour(9).minute(0).second(0).millisecond(0)
      }
      if (!date.hasTime()) {
        nevent.end.hour(9).minute(30).second(0).millisecond(0)
      } else {
        nevent.end.add(30, 'minutes')
      }
      ShowEventModal(nevent, $scope.sites).result.then(function () {
        $scope.refreshCalendar()
      })
    },
    eventClick: $scope.selectEvent,
    eventDrop: function (event, delta, revertFunc, jsEvent, iu, view) {
      checkCollision(event).then(function () {
        $scope.selectedEvent = event
        return event.$save()
      }).catch(revertFunc)
    },
    eventResize: function (event, delta, revertFunc, jsEvent, iu, view) {
      checkCollision(event).then(function () {
        $scope.selectedEvent = event
        return event.$save()
      }).catch(revertFunc)
    }
  }

  $scope.$watchCollection('$storage.selected', $scope.refreshCalendar)
  $scope.$watchCollection('sites', $scope.refreshCalendar)
})
