var app = require('../app')

app.controller('SidebarEventController', function ($scope) {
  $scope.back = function () {
    $scope.event = null
  }

  $scope.getStart = function () {
    if (!$scope.event || !$scope.event.start) {
      return null
    }
    return new Date($scope.event.start)
  }

  $scope.getEnd = function () {
    if (!$scope.event || !$scope.event.end) {
      return null
    }
    return new Date($scope.event.end)
  }

  $scope.getSite = function () {
    if (!$scope.event) {
      return
    }

    var foundSite = null

    $scope.sites.forEach(function (site) {
      if ($scope.event.site === site.id) {
        foundSite = site.name
      }
    })

    return foundSite
  }
})
