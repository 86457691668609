var angular = require('angular')
var moment = require('moment')

var app = require('../app')

var addC = require('./addc')
var transformProp = require('./transform-prop')

var transformDay = function (events) {
  events.forEach(function (event) {
    if (!event.hasOwnProperty('allDay')) {
      return
    }

    if (event.allDay === 'true') {
      event.allDay = true
    } else if (event.allDay === 'false') {
      event.allDay = false
    } else {
      event.allDay = null
    }
  })

  return events
}

var transformDateStringToMoment = function (events) {
  events.forEach(function (event) {
    event.start = moment(event.start)
    event.end = moment(event.end)
  })

  return events
}

var transformTitle = function (events) {
  events.forEach(function (event) {
    event.realTitle = event.title
    if (event.title && event.lastName && event.firstName) {
      event.title = event.lastName + ' ' + event.firstName
    }
  })

  return events
}

var customTransforms = [
  transformDay,
  transformDateStringToMoment,
  transformProp('greenNumber'),
  transformProp('teleMarketing'),
  transformProp('newClient'),
  transformProp('hasAttended'),
  transformTitle
]

var appendTransform = function (defaults, transform) {
  // We can't guarantee that the default transformation is an array
  defaults = angular.isArray(defaults) ? defaults : [defaults]

  // Append the new transformation to the defaults
  return defaults.concat(transform)
}

app.factory('Event', function ($http, $resource) {
  var transformResponse = appendTransform(
    $http.defaults.transformResponse, customTransforms)

  return $resource(
    require('./endpoint'),
    {},
    {
      queryBySite: {
        method: 'GET',
        params: {
          c: 'getEventsBySite'
        },
        isArray: true,
        transformResponse: transformResponse
      },
      queryInterval: {
        method: 'GET',
        params: {
          c: 'getEventsByTime'
        },
        isArray: true,
        transformResponse: transformResponse
      },
      save: {
        method: 'POST',
        transformRequest: [addC('saveEvent')]
      },
      remove: {
        method: 'POST',
        transformRequest: [addC('removeEvent')]
      },
      delete: {
        method: 'POST',
        transformRequest: [addC('removeEvent')]
      }
    })
})
