window.$ = window.jQuery = require('jquery')
require('bootstrap-sass')

var angular = window.angular = require('angular')
require('angular-i18n/hu')
require('angular-animate')
require('angular-resource')
require('angular-ui-bootstrap')
require('ngstorage')

window.moment = require('moment')
require('moment/locale/hu')
window.fullcalendar = require('fullcalendar')
require('angular-ui-calendar')
require('fullcalendar/dist/lang/hu')

var app = angular.module('PrenwebCalendar', [
  'ngAnimate', 'ngResource', 'ui.bootstrap', 'ui.calendar', 'ngStorage'
])

app.config(function ($httpProvider) {
  $httpProvider.defaults.withCredentials = true
})

module.exports = app

require('./values')
require('./resources')
require('./directives')
require('./controllers')
