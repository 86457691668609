var app = require('../app')

app.controller('LoginModalController', function ($scope, $uibModalInstance, User) {
  $scope.user = {}
  $scope.wrongUserPass = false

  $scope.login = function () {
    var user = new User($scope.user)
    user.$login().then(function (res) {
      $scope.wrongUserPass = false
      User.getSession().$promise.then(function (user) {
        $uibModalInstance.close(user)
      }).catch(function (err) {
        console.error(err)
        $scope.wrongUserPass = true
      })
    }).catch(function () {
      $scope.wrongUserPass = true
    })
  }

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel')
  }
})
