var moment = require('moment')

var app = require('../app')

app.controller('TimelineController', function (Event, $localStorage, $scope) {
  $scope.$storage = $localStorage

  var getSelectedSites = function () {
    var sel = []
    $scope.sites.forEach(function (site, index) {
      if ($scope.$storage.selected[index]) {
        sel.push(site.id)
      }
    })
    return sel.join(',')
  }

  $scope.start = moment()
    .hour(0).minute(0).second(0).millisecond(0)
  $scope.getEnd = function () {
    return $scope.start.clone().add(1, 'days')
      .hour(0).minute(0).second(0).millisecond(0)
  }

  $scope.prevDay = function () {
    $scope.start.add(-1, 'days')
    $scope.refreshEvents()
  }

  $scope.nextDay = function () {
    $scope.start.add(1, 'days')
    $scope.refreshEvents()
  }

  $scope.refreshEvents = function () {
    $scope.events = Event.queryInterval({
      sites: getSelectedSites(),
      from: new Date($scope.start),
      to: new Date($scope.getEnd()),
      noHoliday: true
    })
    $scope.events.$promise.then(function (events) {
      events.forEach(function (event) {
        var accessLevel = 0
        $scope.sites.forEach(function (site) {
          if (site.id === event.site) {
            if (!event.color) {
              event.color = site.color
            }
            accessLevel = site.permission
          }
        })
        if (!event.title || !event.title.length) {
          accessLevel = 0
          event.color = 'gray'
        }
        if (event.holiday) {
          accessLevel = 0
        }
        switch (accessLevel) {
          case 0:
            event.editable = false
            break
          case 1:
            event.editable = false
            break
          case 2:
            event.editable = true
            event.deletable = true
            break
        }
      })
      $scope.events = $scope.events.filter(function (event) {
        return event.title
      })
    })
  }

  $scope.$watch('$storage.selected', $scope.refreshEvents)

  $scope.refreshEvents()
})
