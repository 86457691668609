var app = require('../app')

app.directive('navbarUser', function () {
  return {
    replace: true,
    restrict: 'EA',
    scope: {
      user: '='
    },
    templateUrl: 'assets/partials/navbar-user.html',
    controller: 'LoginController'
  }
})
