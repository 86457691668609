var angular = require('angular')

var app = require('../app')

app.controller('ProfileModalController', function ($scope, $uibModalInstance, User, user) {
  $scope.user = angular.extend({}, user)
  $scope.error = false

  $scope.checkNewPass = function () {
    return $scope.user.password_new === $scope.user.password_new_confirmation
  }

  $scope.submit = function () {
    if (!$scope.checkNewPass()) {
      return false
    }
    var u = new User($scope.user)
    u.$save().then(function (res) {
      $uibModalInstance.close(new User($scope.user))
    }).catch(function () {
      $scope.error = true
    })
  }

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel')
  }
})
