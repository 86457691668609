var app = require('../app')

app.factory('ShowEventModal', function ($uibModal) {
  return function (event, sites) {
    return $uibModal.open({
      templateUrl: 'assets/partials/modal-event.html',
      controller: 'EventModalController',
      resolve: {
        event: function () {
          return event
        },
        sites: function () {
          return sites
        }
      }
    })
  }
})

app.factory('ShowProfileModal', function ($uibModal) {
  return function (user) {
    return $uibModal.open({
      templateUrl: 'assets/partials/modal-profile.html',
      controller: 'ProfileModalController',
      resolve: {
        user: function () {
          return user
        }
      }
    })
  }
})
