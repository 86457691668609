var app = require('../app')

app.factory('Title', function ($resource) {
  return $resource(
    require('./endpoint'),
    {},
    {
      query: {
        method: 'GET',
        params: {
          c: 'getAllTitles'
        },
        isArray: true
      }
    })
})
