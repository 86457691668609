var angular = require('angular')

var app = require('../app')

var addC = require('./addc')
var transformProp = require('./transform-prop')

var customTransforms = [
  transformProp('disableParallelEvents')
]

var appendTransform = function (defaults, transform) {
  // We can't guarantee that the default transformation is an array
  defaults = angular.isArray(defaults) ? defaults : [defaults]

  // Append the new transformation to the defaults
  return defaults.concat(transform)
}

app.factory('Site', function ($http, $resource) {
  var transformResponse = appendTransform(
    $http.defaults.transformResponse, customTransforms)

  return $resource(
    require('./endpoint'),
    {},
    {
      query: {
        method: 'GET',
        params: {
          c: 'getAllSites'
        },
        isArray: true,
        transformResponse: transformResponse
      },
      queryByUser: {
        method: 'GET',
        params: {
          c: 'getAllSitesByUser'
        },
        isArray: true,
        transformResponse: transformResponse
      },
      queryByEvent: {
        method: 'GET',
        params: {
          c: 'getAllSitesByEvent'
        },
        isArray: true,
        transformResponse: transformResponse
      },
      save: {
        method: 'POST',
        transformRequest: [addC('addSite')]
      },
      remove: {
        method: 'POST',
        transformRequest: [addC('deleteSite')]
      },
      delete: {
        method: 'POST',
        transformRequest: [addC('deleteSite')]
      }
    })
})
