module.exports = function transformProp (prop) {
  return function (events) {
    events.forEach(function (event) {
      if (!event.hasOwnProperty(prop)) {
        return
      }

      if (event[prop] === '1') {
        event[prop] = true
      } else {
        event[prop] = false
      }
    })

    return events
  }
}
