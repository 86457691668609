var app = require('../app')

app.directive('calTimeline', function () {
  return {
    replace: true,
    restrict: 'EA',
    scope: {
      sites: '=',
      selectedSites: '=',
      selectEvent: '&'
    },
    templateUrl: 'assets/partials/timeline.html',
    controller: 'TimelineController'
  }
})
