var app = require('../app')

app.directive('sidebar', function () {
  return {
    replace: true,
    restrict: 'EA',
    scope: false,
    templateUrl: 'assets/partials/sidebar.html',
    controller: 'SidebarController'
  }
})
