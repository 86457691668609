var angular = require('angular')

module.exports = function (c) {
  return function (data, headersGetter) {
    if (!data) {
      return null
    }

    var json = angular.toJson(data)
    json = JSON.parse(json)
    if (data.start) {
      json.start = data.start.clone().utc().format()
    }
    if (data.end) {
      json.end = data.end.clone().utc().format()
    }
    if (data.realTitle) {
      json.title = data.realTitle
    }
    json.c = c
    json = JSON.stringify(json)
    return json
  }
}
