var angular = require('angular')

var app = require('../app')

app.controller('EventModalController', function ($scope, $uibModalInstance, Session, Event, Title, PRCode, event, sites) {
  $scope.event = angular.extend({}, event)
  $scope.event.title = event.realTitle
  delete $scope.event.realTitle
  $scope.event.start = event.start.clone()
  $scope.event.end = event.end.clone()
  $scope.sites = sites
  $scope.titles = Title.query()
  $scope.date = new Date($scope.event.start)
  $scope.prCodes = []

  $scope.$watch('date', function () {
    $scope.event.start.year($scope.date.getFullYear())
                      .month($scope.date.getMonth())
                      .date($scope.date.getDate())
    $scope.event.end.year($scope.date.getFullYear())
                    .month($scope.date.getMonth())
                    .date($scope.date.getDate())
  })

  var getSiteFromId = function (id) {
    var foundSite = null
    $scope.sites.forEach(function (site) {
      if (site.id === id) {
        foundSite = site
      }
    })
    return foundSite
  }

  var updateDuration = function () {
    var dur = null
    if (!$scope.event.site) {
      return
    }
    $scope.titles.forEach(function (title) {
      if ($scope.event.title === title.title) {
        dur = title.durations[$scope.event.site]
      }
    })
    if (!dur) {
      return
    }
    $scope.event.end = $scope.event.start.clone().add(dur, 'minutes')
  }

  $scope.$watch('event.site', updateDuration)
  $scope.$watch('event.site', function () {
    $scope.prCodes = PRCode.query({siteId: $scope.event.site})
  })
  $scope.$watch('event.title', updateDuration)

  $scope.startHour = function (val) {
    if (val === undefined) {
      return $scope.event.start.hour()
    }
    var diff = val - $scope.event.start.hour()
    $scope.event.start.add(diff, 'hours')
    $scope.event.end.add(diff, 'hours')
  }

  $scope.startMinute = function (val) {
    if (val === undefined) {
      return $scope.event.start.minute()
    }
    var diff = val - $scope.event.start.minute()
    $scope.event.start.add(diff, 'minutes')
    $scope.event.end.add(diff, 'minutes')
  }

  $scope.errorText = null

  $scope.step = 1

  $scope.dateOptions = {
    startingDay: 1
  }

  $scope.datePickerOpen = false

  $scope.openDatePicker = function () {
    $scope.datePickerOpen = true
  }

  $scope.back = function () {
    $scope.step = 1
  }

  $scope.next = function () {
    $scope.step = 2
  }

  var checkSites = function (event) {
    return event.site && event.site.length > 0
  }

  var validate = function (event) {
    if (!checkSites(event)) {
      $scope.step = 1
      $scope.errorText = 'Válaszd ki a telephelyet!'
      return false
    }

    if (!event.title) {
      $scope.step = 1
      $scope.errorText = 'Esemény név szükséges!'
      return false
    }

    if (event.start.year() !== event.end.year() ||
        event.start.month() !== event.end.month() ||
        event.start.date() !== event.end.date()) {
      $scope.step = 1
      $scope.errorText = 'Esemény vége nem eshet a következő napra!'
      return false
    }

    return true
  }

  var checkCollision = function (event) {
    if (Session.user.admin) {
      return Promise.resolve(false)
    }
    if (getSiteFromId(event.site).disableParallelEvents) {
      return Event.queryInterval({
        sites: [event.site],
        from: new Date(event.start),
        to: new Date(event.end)
      }).$promise.then(function (events) {
        if (events.length > 1) {
          $scope.step = 1
          $scope.errorText = 'Már létezik felvett esemény ebben az időpontban'
          return Promise.reject(new Error('event exists'))
        } else if (events.length === 1 && events[0].id !== event.id) {
          $scope.step = 1
          $scope.errorText = 'Már létezik felvett esemény ebben az időpontban'
          return Promise.reject(new Error('event exists'))
        } else {
          return Promise.resolve(false)
        }
      })
    } else {
      return Promise.resolve(false)
    }
  }

  $scope.submit = function () {
    if (!validate($scope.event)) {
      return false
    }

    var event = new Event($scope.event)
    checkCollision(event).then(function () {
      return event.$save()
    }).then(function () {
      $uibModalInstance.close(event)
    }).catch(function (err) {
      console.error(err)
    })
  }

  $scope.delete = function () {
    event.$delete().then(function () {
      $uibModalInstance.close(false)
    })
  }

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel')
  }
})
