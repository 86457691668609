var app = require('../app')

app.factory('PRCode', function ($resource) {
  return $resource(
    require('./endpoint'),
    {},
    {
      query: {
        method: 'GET',
        params: {
          c: 'getPR'
        },
        isArray: true
      }
    })
})
