var app = require('../app')

var addC = require('./addc')

app.factory('User', function ($resource) {
  return $resource(
    require('./endpoint'),
    {},
    {
      getSession: {
        method: 'GET',
        params: {
          c: 'checkLogin'
        }
      },
      login: {
        method: 'POST',
        transformRequest: [addC('checkLogin')]
      },
      logout: {
        method: 'POST',
        transformRequest: [addC('Logout')]
      },
      query: {
        method: 'GET',
        params: {
          c: 'getAllUsers'
        },
        isArray: true
      },
      save: {
        method: 'POST',
        transformRequest: [addC('saveUser')]
      },
      remove: {
        method: 'POST',
        transformRequest: [addC('deleteUser')]
      },
      delete: {
        method: 'POST',
        transformRequest: [addC('deleteUser')]
      }
    })
})
