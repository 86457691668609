var app = require('../app')

var created = false

app.controller('SidebarController', function ($scope, $rootScope) {
  $rootScope.showSidebar = true

  if (created) {
    console.error('Error: Multiple sidebar controller instances.')
  }
  created = true

  $scope.toggleSidebar = function () {
    $rootScope.showSidebar = !$rootScope.showSidebar
  }
})
