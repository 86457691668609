var app = require('../app')

app.directive('sidebarSites', function () {
  return {
    replace: true,
    restrict: 'EA',
    scope: {
      sites: '=',
      selectedSites: '='
    },
    templateUrl: 'assets/partials/sidebar-sites.html',
    controller: 'SidebarSitesController'
  }
})
