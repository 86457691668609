var app = require('../app')

app.controller('LoginController', function ($scope, $rootScope, $uibModal, Session, ShowProfileModal) {
  $scope.session = Session
  $scope.beginLogin = function () {
    var modal = $uibModal.open({
      templateUrl: 'assets/partials/modal-login.html',
      controller: 'LoginModalController'
    })

    modal.result.then(function (user) {
      Session.user = user
    })
  }

  $scope.showProfile = function () {
    ShowProfileModal(Session.user).result.then(function (user) {
      Session.user = user
    })
  }

  $scope.logout = function () {
    Session.user.$logout().then(function () {
      window.location.reload()
    })
  }
})
